.container {
  position: relative;
  display: inline-block;
}

.tooltip {
  position: absolute;
  top: -150%;
  left: 50%;
  transform: translateX(-50%);
  background-color: #333;
  color: #fff;
  padding: 5px;
  border-radius: 3px;
  font-size: 14px;
  opacity: 0.9;
  pointer-events: none;
  white-space: nowrap;
  z-index: 100;
}
