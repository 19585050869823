.my-custom-dropdown:hover {
    position: relative;

    ul {
        display: block;
        
        li:hover {
            text-decoration: underline;
        }
    }
    
}

.product-desk {
    overflow: hidden;
}

.product-desk table, .product-desk th, .product-desk td {
    border: 1px solid;
}

header .nav-row .custom-nav {
    display: block;
    justify-content: center;
    width: auto;
}

.custom-delete-product-cart {
    border: none;
    background: transparent;
    color: #cf072c; 
}

.custom-delete-product-cart:hover {
    text-decoration: underline;
}

.margin-right-4 {
    margin-right: 4px;
}

.container-a-not-href {
    a:not([href]) {
        color: #cf072c;
    }
    a:not([href]):not([tabindex]):hover {
        text-decoration:none;
        background-color:#e20020;
        color:#fff
    }
}

.pager .reset li a:not([href]):not([tabindex]):hover {
    text-decoration:none;
    background-color:#e20020;
    color:#fff
}

.custom-cart-image {
    height: 56px;
    width: 56px;
}

.custom-delivery-image {
    height: 40px;
    width: 70px;
}

.custom-widget {
    background-color: transparent;
    position: relative;
    border: 1px solid #d8d8d8;
    height: 216px;
}

.custom-jersey-image {
    max-width: 216px;
    margin: 0 auto;
    display: block;
    position: relative;
    width: auto;
    height: 216px;
}

.custom-jersey-preview-text {
    height: 124px;
    width: 70px;
    position: absolute;
    left: 50%;
    top: 40%;
    transform: translate(-49%, -45px);
    text-align: center;
    text-transform: uppercase;
}

.custom-jersey-name {
    height: 20%;
    margin-top: 5px;
    font-weight: bold;
    font-family: "AgencySport", sans-serif;
    font-size: 20px;
}

.custom-red-jersey {
    color: #ffffff;
}

.custom-white-jersey {
    color: #cf072c;
}

.custom-jersey-number {
    height: 80%;
    display: flex;
    align-content: center;
    justify-content: center;
    flex-direction: row;
    align-items: center;
    text-transform: uppercase;
    font-weight: normal;
    font-family: "Rubleny", sans-serif;
    font-size: 70px;
    margin-top: -15px;
    -webkit-text-stroke: 1px blue;
}

.custom-jersey-box {
    display: flex;
    flex-direction: column;
    margin: 10px 0 0 0;
}

.custom-number-name-inputs {
    display: flex;
}

.custom-jersey-number-input {
    width: 50%;
    -webkit-appearance: none;
    appearance: none;
    border: solid 1px #e5e5e5;
    height: 38px;
    font-size: 13px;
    line-height: 1.85;
}

.custom-jersey-name-input {
    width: 100%;
    -webkit-appearance: none;
    appearance: none;
    border: solid 1px #e5e5e5;
    height: 38px;
    font-size: 13px;
    line-height: 1.85;
}

@font-face {
    font-family: 'AgencySport';
    src: url('./assets/fonts/Agency-Sport_1.otf') format('opentype');
}

.custom-checkbox:checked {
    accent-color: #cf072c;
}

.custom-checkbox {
    height: 25px;
    width: 25px;
    background-color: #d4d4d4;
    vertical-align: middle;
}

.custom-jersey-prise {
    color: #cf072c;
    font-size: 25px;
}

.custom-get-gop {
    width: max-content;
}

.custom-popup-container {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 100000;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
}
.custom-popup-body {
    position: absolute;
    left: 30%;
    right: 30%;
    bottom: 30%;
    top: 30%;
    text-align: center;
    margin: auto;
    border-radius: 15px;
    border-color: black;
    background: white;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.custom-btn-popup {
    background-color: #fff;
    border: solid 1px #cf072c;
    font-size: 13px;
    font-weight: 700;
    line-height: 1.85;
    text-align: center;
    color: #cf072c;
    border-radius: 0;
    padding: 5px 20px;
    transition: all 0.2s;
}

.custom-product-bonuses {
    font-size:13px;
    font-weight:500;
    line-height:1.14;
    color:#cf072c;
    text-transform:uppercase;
    margin-bottom:24px
}

.new-price {
    color:#cf072c;
    padding: 0px;
}
  
.old-price {
    text-decoration: line-through;
}